import { normalize } from "normalizr";
import { REQUEST_ORG_SUCCESS, RequestOrgSuccessAction } from "./types";
import { OrgFragment } from "../../queries";
import { NormalizedOrgEntities, orgNormalization } from "./normalization";

export const requestOrgSuccess = (
  orgById: OrgFragment
): RequestOrgSuccessAction => {
  const normalizedData = normalize<unknown, NormalizedOrgEntities>(
    orgById,
    orgNormalization.schema
  );

  const organization = normalizedData.entities.orgs
    ? Object.values(normalizedData.entities.orgs)[0]
    : undefined;

  return {
    type: REQUEST_ORG_SUCCESS,
    payload: {
      organization,
    },
  };
};
