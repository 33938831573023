import { Modify } from "../storeTypes";
import { Maybe, OrgFragment } from "../../queries";

export interface OrganizationState {
  id: string;
  brandChannelId?: string;
  defaultThemeId?: string;
  featureFlags: Maybe<string>[];
  loggingUrlOverride?: string;
}

export type NormalizedOrgFragment = Modify<
  OrgFragment,
  {
    themeByDefaultChannelThemeId?: string | null | undefined;
  }
>;

export type OrgAction = RequestOrgSuccessAction;

export const REQUEST_ORG_SUCCESS = "REQUEST_ORG_SUCCESS";

export interface RequestOrgSuccessAction {
  type: typeof REQUEST_ORG_SUCCESS;
  payload: {
    organization: NormalizedOrgFragment | null | undefined;
  };
}
