import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { App } from "../../../../store/apps/types";
import { PlayerState } from "../../../../store/rootReducer";
import { Theme } from "../../../../store/themes/types";
import { ScreenData, ScreenState } from "../../../../store/screen/types";
import { ContextConfig, DeviceConfig } from "../../../../store/config/types";
import { activeChannelId } from "../../../../store/screen/selectors";
import { requestAppSuccess } from "../../../../store/apps/actions";
import { Loading } from "../../../core/components/Loading/Loading";
import { EntityType } from "@screencloud/signage-firestore-client";
import { LiveUpdateConnector } from "../../../core/containers/LiveUpdatesContainer/LiveUpdateConnector";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import useThemeId from "../../../../utils/useThemeId";
import { SECOND_DURATION_MS } from "../../../../constants";
import { useAppInstanceFiles } from "./useAppInstanceFiles";
import { useInitialPlaybackPosition } from "../../../../utils/useInitialPlaybackPosition";

import { OrganizationState } from "../../../../store/organization/types";
import { useAppInstanceRoot } from "../../../../queries";
import { ContentFailureGenericCb } from "../TimelineViewer/types";
import { Logger } from "../../../../logger/logger";
import { useGetAdditionContextKey } from "../../../../utils/qrCode";
import { AudioSettings } from "../../../../providers/AudioSettingsProvider/types";
import { AudioSettingsContext } from "../../../../providers/AudioSettingsProvider/AudioSettingsProvider";
import { captureException } from "../../../../utils/bugTracker";
import { AppViewer } from "../AppViewer/AppViewer";
import { useOverrideAppInitialize } from "./useOverrideAppInitialize";

export const log = new Logger("appViewerContainer");
interface AppViewerContainerProps {
  id: string;
  isPreload?: boolean;
  fullDurationMs: number;
  isRoot?: boolean;
  itemStartTimestamp?: number;
  onContentFailure: ContentFailureGenericCb;
}

export const AppViewerContainer = (
  props: AppViewerContainerProps
): ReactElement<AppViewerContainerProps> => {
  const dispatch = useDispatch();
  const isPreview = useSelector<PlayerState, boolean>(
    (state) => state.config.contentConfig.isPreview
  );
  const contextConfig = useSelector<PlayerState, ContextConfig>(
    (state) => state.config.contextConfig || {}
  );

  const region = useSelector<PlayerState, string>(
    (state) => state.config.region
  );

  const app = useSelector<PlayerState, App | undefined>((state) => {
    if (!isPreview) {
      return state.apps.byId[props.id];
    }
    return undefined;
  });

  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  const audioSettings = useContext<AudioSettings>(AudioSettingsContext);

  const overrideAppInitialize = useOverrideAppInitialize(isPreview);

  // Only fetch when either objects are false
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(
    !!app || !!overrideAppInitialize
  );

  const [fetchApp, { data }] = useAppInstanceRoot({
    useCache: false,
    skipCache: true,
    variables: {
      id: props.id,
    },
  });

  // watch for data dependency only if appInstanceById change to prevent too many dispatch call
  // we’re only using a appInstanceById on the data object.
  // so instead of useEffect depend on the whole data we only depend on appInstanceById object
  useEffect(() => {
    if (data?.appInstanceById) {
      dispatch(requestAppSuccess(data.appInstanceById));
      setIsDataLoaded(true);
    }
  }, [data?.appInstanceById, dispatch]);

  useInitialFetch(!!props.isRoot && !isPreview, fetchApp);

  const channelId = useSelector<PlayerState, string | undefined>(
    activeChannelId
  );

  const themeId = useThemeId(channelId);

  const theme = useSelector<PlayerState, Theme | undefined>((state) =>
    themeId ? state.themes.byId[themeId] : undefined
  );

  const screenData = useSelector<PlayerState, ScreenData | undefined>(
    (state) => state.screen.screenData
  );

  const org = useSelector<PlayerState, OrganizationState | undefined>(
    (state) => state.organization
  );
  const featureFlags = org?.featureFlags;
  const orgId = org?.id;

  const screenId = useSelector<PlayerState, string | undefined>(
    (state) => state.screen?.id
  );

  const spaceId = useSelector<PlayerState, string | undefined>(
    (state) => state.screen?.spaceId || undefined
  );

  const filesByAppInstanceId = useAppInstanceFiles(props.id, isPreview);

  const device = useSelector<PlayerState, DeviceConfig | undefined>(
    (state) => state.config.device
  );

  const appViewerToken = useSelector<PlayerState, string | undefined>(
    (state) => state.config.appViewerToken
  );

  // Using a key to re-mount the app when app data objects are updated
  // TODO - Should this be handled by the key in GenericViewer? State here will not remount the component, just re-render it.
  const [reMountKey, setReMountKey] = useState<number | undefined>();
  const isFirstRun = useRef(true);

  const initialPlaybackPositionMs = useInitialPlaybackPosition(
    props.itemStartTimestamp,
    props.fullDurationMs,
    reMountKey
  );

  const additionalContextKey = useGetAdditionContextKey();

  const [displayAppWithoutToken, setDisplayAppWithoutToken] = useState<boolean>(
    false
  );
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!appViewerToken) {
      const displayAppTimeout = setTimeout(() => {
        setDisplayAppWithoutToken(true);
        captureException(
          new Error(
            !appViewerToken
              ? `Viewer app unable to display - no appViewerToken`
              : `Viewer app unable to display - appViewerToken has expired`
          )
        );
      }, SECOND_DURATION_MS * 20); // 20 Seconds

      const displayLoadingTimeout = setTimeout(() => {
        setDisplayLoading(true);
      }, SECOND_DURATION_MS * 2); // 2 Seconds
      return () => {
        clearTimeout(displayAppTimeout);
        clearTimeout(displayLoadingTimeout);
      };
    }
  }, [appViewerToken]);

  /**
   * When data changes, set a new key which will mount a new child AppViewer component
   * i.e. trigger any unmount code as well
   **/

  useEffect(() => {
    // Do not update the stack on the initial render.
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setReMountKey(new Date().getTime());
  }, [
    app,
    overrideAppInitialize,
    featureFlags,
    theme,
    audioSettings.shouldMuteMedia,
    screen.isMuted,
  ]);

  // TODO fix rerender and remove useffect hook
  useEffect(() => {
    log.debug({
      message: `Show App container`,
      context: {
        viewUrl: app?.viewerUrl,
        name: app?.name,
        contentType: "app",
        isPreview: isPreview,
        isPreload: props.isPreload,
      },
    });
    // we only want to trigger log line if app id or isPreload status have changed
    // todo: solve the possible outdated information problem in the log line above
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.id, props.isPreload]);

  if (!app && !overrideAppInitialize) {
    return (
      <p data-testid="can-not-find-app">
        Sorry, we can&apos;t find the app you are looking for.
      </p>
    );
  }

  const appWithOverrideAppConfig: App | undefined = app
    ? {
        ...app,
        config: {
          ...app?.config,
          mute:
            app?.config.mute || audioSettings.shouldMuteMedia || screen.isMuted,
        },
      }
    : undefined;

  // Display the app when we have an appViewerToken or
  // after 20 seconds of no appViewerToken render the app anyways
  // some apps will require an appViewerToken and will fail to load data without one
  if (appViewerToken || displayAppWithoutToken) {
    return (
      <>
        <LiveUpdateConnector
          entityId={props.id}
          entityType={EntityType.APP_INSTANCE}
        />
        {!isDataLoaded ? (
          <Loading />
        ) : (
          <AppViewer
            key={reMountKey}
            app={appWithOverrideAppConfig}
            overrideAppInitialize={overrideAppInitialize}
            theme={theme}
            orgId={orgId}
            screenId={screenId}
            spaceId={spaceId}
            screenData={screenData}
            filesByAppInstanceId={filesByAppInstanceId}
            fullDurationMs={props.fullDurationMs}
            isPreload={props.isPreload}
            contextConfig={contextConfig}
            isPreview={isPreview}
            device={device}
            initialPlaybackPositionMs={initialPlaybackPositionMs}
            durationElapsedMs={initialPlaybackPositionMs}
            featureFlags={featureFlags}
            onContentFailure={props.onContentFailure}
            region={region}
            appViewerToken={appViewerToken}
            additionalContextKey={additionalContextKey}
          />
        )}
      </>
    );
  }

  // Show the loading screen until we have an appViewerToken or 2 seconds have passed without one.
  if (displayLoading) {
    return <Loading />;
  }
  return <></>;
};
