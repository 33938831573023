import { ConfigState } from "../../../../store/config/types";
import { replaceWithSecureURL } from "../../../../utils/FileProcessingClientWrapped";
import { ignoreToCacheTheseParamsOnOurNativePlayers } from "../../../../utils/helpers";
import { extractOrgIdFromUrl } from "../AppViewerContainer/useAppInstanceFiles";

export const handleSecureMediaUrl = (
  url: string,
  opts: {
    secureMediaServiceURL?: string;
    secureMediaPolicy?: string;
    secureMediaUrlsEnabled?: boolean;
    orgId?: string;
    config: ConfigState;
  }
): string => {
  const {
    orgId,
    secureMediaServiceURL,
    secureMediaPolicy,
    secureMediaUrlsEnabled,
    config,
  } = opts;
  const isSecureMediaEnabled =
    secureMediaServiceURL && secureMediaPolicy && secureMediaUrlsEnabled;
  if (url && isSecureMediaEnabled) {
    const extractedOrgId = extractOrgIdFromUrl(url);
    if (extractedOrgId === orgId) {
      console.log(extractedOrgId, config, url, secureMediaServiceURL);
      return `${replaceWithSecureURL(
        config,
        url,
        secureMediaServiceURL
      )}${ignoreToCacheTheseParamsOnOurNativePlayers(secureMediaPolicy)}`;
    }
  }
  return url;
};
